
import { defineComponent, ref, watch } from "vue";
import axios from "@/api/axios";
import UiInput from "@/components/UI/Input";
import UiSelect from "@/components/UI/Select";
import Toast from "@/components/UI/Toast";
import qs from "qs";
import { useRouter, useRoute } from "vue-router";

export interface Iaddress {
  receiver: string;
  contactTel: string;
  address: string;
  receiveAddress: string;
  isDefault: boolean;
  province: string;
  country: string;
  city: string;
}
export default defineComponent({
  name: "addAddress",
  components: {
    UiInput,
    UiSelect,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.query.id || "";
    const addressType = route.query.addressType

    const address = ref<Iaddress>({
      receiver: "",
      contactTel: "",
      address: "",
      receiveAddress: "",
      isDefault: true,
      province: "",
      country: "",
      city: "",
    });
    if (id) {
      axios
        .get("/M/User/AddAddress/" + id)
        .then((res) => {
          const data = res.data.obj.address;
          const province = data.province || "";
          const city = data.city || "";
          const country = data.country || "";
          address.value = { ...data, address: province + " " + city + " " + country };
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function back() {
      router.go(-1);
    }

    function save() {
      let secondCity = '';
      let thirdCountry = '';
      if(address.value.address && address.value.address.split(" ")[0]){
        const firstPro = address.value.address.split(" ")[0];//省份
          if('北京，上海，天津，重庆'.indexOf(firstPro) != -1){
            secondCity =firstPro;
            thirdCountry= (address.value.address && address.value.address.split(" ")[1]) || "";
          }else{
            secondCity =(address.value.address && address.value.address.split(" ")[1]) || "";
            thirdCountry= (address.value.address && address.value.address.split(" ")[2]) || "";
          }
      }
      const data = qs.stringify({
        id,
        receiver: address.value.receiver,
        contactTel: address.value.contactTel,
        receiveAddress: address.value.receiveAddress,
        province: (address.value.address && address.value.address.split(" ")[0]) || "",
        country: thirdCountry,
        city: secondCity,
        addressType: addressType
      });
      // console.log('11111',address.value);
      // console.log('22222',data);
      axios
        .post("/M/Server/DoSaveAddress", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: res.data.msg,
              onClose: () => {
                back();
              },
            });
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return {
      address,
      save,
      back
    };
  },
});
